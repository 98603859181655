.side-nav{
    background-color: #FFFFFF;
    width: 260px;
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100vh;
    transition: all 0.5s;
    padding: 0 20px;
    left: -100%;
    z-index: 4;
}

.side-nav.active{
    left: 0;
}

.side-nav ul{
    list-style: none;
    padding: 0;
    color: #cccccc;
}

.side-nav ul li{
    margin: 6px 0;
}

.side-nav ul li svg{
    margin-right: 10px;
}

.side-nav ul li a{
    color: rgb(49, 49, 49);
    text-decoration: none;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    transition: 0.3s;

}

.side-nav a:hover,
.side-nav a.active{
    text-decoration: none;
    color: #FFFFFF;
    background-color: #b01e0e;
}

