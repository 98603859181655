.card-de-status{
    border: 0 !important;
    border-radius: 10px !important;
}

.borda-vermelha{
    border-left: 5px solid #b01e0e !important;
}

.card-de-status .titulo{
    display: block;
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.card-de-status .icone-card{
    font-size: 45px;
    color: #cccccc;
}