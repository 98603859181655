.background {
    background: linear-gradient(180deg, rgba(176,30,14,1) 43%, rgba(248,68,68,1) 100%);
    background-size: cover;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

.card-login{
    margin: 0 auto;
    width: 500px;
    margin-top: 100px;
}

.img-login{
    width: 100%;
    height: 25rem;
    max-height: 350px;
    margin-bottom: 35px;
}

@media(max-width: 993px){
    .card-login{
        width: 100%;
        margin-top: 60px;
    }
    .img-login{
        display: none;
    }
}