.menu{
    width: 100%;
    background-color: #DC3545;
}

.menu .menuItem{
    color: #FFFFFF;
    border: 0;
    background-color: transparent;
    padding: 1rem;
    font-weight: 600;
}

.menuItem:hover{
    background-color: #FFFFFF;
    color: #DC3545;
}

.menuItem-selected{
    border: 0;
    background-color: #FFFFFF;
    color: #DC3545;
    padding: 1rem;
    font-weight: 600;
}