*{
    font-family: 'Nunito', sans-serif;
    box-sizing: border-box;
}

a:hover{
    text-decoration: none;
}

.main{
    padding: 20px;
    transition: all 0.5s;
}

.main-active{
    padding: 20px;
    margin-left: 260px;
    transition: all 0.5s;
}

.overlayLoading{
    display: none;
    position: fixed;
    z-index: 1000000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

._loading_overlay_content {
    top: 40%;
    left: 48%;
    position: fixed;
  }


.statusAgendada{
    background-color: #d7f8da;
}

.icone-botao{
    cursor: pointer;
}

.btn-vermelho-outline{
    background-color: #FFFFFF;
    border: 2px solid #b01e0e;
    color: #b01e0e;
    transition: 0.3s;
}

.btn-vermelho-outline:hover{
    background-color: #b01e0e;
    border: 2px solid #b01e0e;
    color: #FFFFFF;
}

.btn-branco{
    background-color: #FFFFFF;
    border: 2px solid #FFFFFF;
    color: #b01e0e;
}

.btn-branco:hover{
    background-color: #FFFFFF;
    border: 2px solid #FFFFFF;
    color: #b01e0e;
}

.bg-light{
    background-color: #FFFFFF !important;
    border: solid 1px #FFFFFF !important;
}

.bg-outline-vermelho{
    color: #DC3545;
    background-color: #FFFFFF;
    border: 1px solid #DC3545;
}

.bg-vermelho-2{
    color: #FFFFFF;
    background-color: rgba(248,68,68,1);
}

.mt-10{
    margin-top: 100px;
}

.font-30{
    font-size: 30px !important;
}

.text-gray-800{
    color: #5a5c69 !important;
}

.text-vermelho-bold{
    color: #DC3545 !important;
    font-weight: bold;
}

.text-vermelho{
    color: #DC3545 !important;
}

.chart-100{
    display: block;
    width: 98%;
}

.scroll-content{
    max-height: 25rem;
    overflow: auto;
}

@media(max-width: 1024px){

    .main-active{
        padding: 20px;
        margin-left: 0;
        transition: all 0.5s;
        z-index: 4;
    }
    
    .mt-10{
        margin-top: 30px;
    }
}



