.cabecalho{
    background: linear-gradient(180deg, rgba(176,30,14,1) 43%, rgba(248,68,68,1) 100%);
    padding: 23px;
    transition: all 0.5s;
}

.cabecalho h2{
    font-size: 1.3rem;
    margin: 0 20px;
    color: #FFFFFF;
    display: inline-block;
    vertical-align: middle;
}

.ativo{
    margin-left: 260px;
    transition: all 0.5s;
}


@media(max-width: 994px){
    .cabecalho h2{
        display: none !important;
    }
}